import { StarIcon } from 'lucide-react'
import { GoogleLogo } from '#app/components/icons/google-logo.tsx'
import { Card, CardContent } from '#app/components/ui/card'
import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from '#app/components/ui/carousel'

const REVIEWS = [
	{
		user: 'David Stokláska',
		review:
			'Mám byt se dvěma velkými psy, takže výběr firmy a následně paní na úklid je pro mě více než důležitý. Po zvažování všech aspektů jsem si vybral Izzy a jsem rád za tento výběr - nešlápl sem vedle. Paní dokáže úklid se psy velmi dobře skloubit a k psům má velmi hezký vztah. Komunikace jak s firmou, tak s paní na úklid je velmi dobré. na všem se dá domluvit, jakékoliv požadavky na úklid z mojí strany jsou akceptovány a splněny tak, aby došlo k mojí spokojenosti. Můžu doporučit :)',
	},
	{ user: 'Martina Mračková', review: 'S úklidem jsem byla velice spokojená!' },
	{
		user: 'Xenie Solotova',
		review: 'Skvelá aplikace a perfektní úklid! Děkujeme',
	},
]

export function GoogleReviews() {
	return (
		<div className="container px-20">
			<Carousel className="w-full">
				<CarouselContent>
					{REVIEWS.map((review, index) => (
						<CarouselItem
							key={index}
							className="max-w-fit md:max-w-none md:basis-1/3"
						>
							<ReviewCard review={review.review} user={review.user} />
						</CarouselItem>
					))}
				</CarouselContent>
				<CarouselPrevious />
				<CarouselNext />
			</Carousel>
		</div>
	)
}

const ReviewCard = ({ review, user }: { review: string; user: string }) => {
	const MAX_LENGTH = 200

	return (
		<div className="p-1">
			<Card className="h-[500px] bg-gray-200">
				<CardContent className="flex h-full flex-col justify-between overflow-hidden p-6">
					<div className="flex flex-col gap-4">
						<div className="flex flex-row items-center gap-2">
							<img
								alt={'reviewer'}
								src={'/img/user.png'}
								className="h-[45px] w-[45px] rounded-lg bg-accent"
							/>
							<div>
								<p>{user}</p>
								<div className="ml-1 flex flex-row">
									{Array.from({ length: 5 }).map((_, index) => (
										<StarIcon
											className="-ml-2 scale-50 lg:ml-0 lg:scale-100"
											key={index}
											fill="#f2ac29"
											color="#f2ac29"
										/>
									))}
								</div>
							</div>
						</div>
						<span className="text-sm lg:hidden">
							{review.substring(0, MAX_LENGTH)}...
						</span>
						<span className="hidden text-sm lg:flex">{review}</span>
					</div>
					<div className="flex flex-row gap-2">
						<GoogleLogo />
						<p className="text-sm">
							Recenze z{' '}
							<a
								href="https://www.google.com/search?sca_esv=1e22c0f3b30bddf3&sca_upv=1&q=Izzy+uklid&uds=ADvngMju_MCfyrnwjhN4j4QPdHoBQxADXB37CMpdENhYYYHEJAQ1vgv_uNnv00XRiCc0G-_jVIde8L4y_XWZN0ovFVzIN66TBq-juxaNJZw9oC1Ird8VGpE&si=ACC90nwjPmqJHrCEt6ewASzksVFQDX8zco_7MgBaIawvaF4-7hbZXbjbaXLa2u3tTajKlrkTzo7chehVaXYNuyELB0wtskZR0_hsaFRLL3_ECMigwCmiKwg%3D&sa=X&ved=2ahUKEwitoeTzraGHAxUphv0HHSOWDuwQ3PALegQIFRAE&biw=1800&bih=985&dpr=2"
								className="text-blue-500"
							>
								Google
							</a>
						</p>
					</div>
				</CardContent>
			</Card>
		</div>
	)
}
