const REVIEWS = [
	{
		reviewer: 'David S.',
		review:
			'„Po příchodu domů jsem se díval na všechny detaily, jelikož mě paní poprosila o zpětnou vazbu. Mohl jsem říct jen wau, jelikož jsem nenašel žádnou chybu - věnovala se každému detailu, na kterém jsme se domluvili ☺️☺️“',
	},
	{
		reviewer: 'Dennis F.',
		review:
			'„Skvělý úklid, jsme velice spokojeni. Plánujeme objednávat pravidelně. Díky moc“',
	},
	{
		reviewer: 'Lenka K.',
		review:
			'„Celkově s úklidem velká spokojenost, paní dorazila včas a komunikace byla v naprostém pořádku. Úklid měl pár detailů ke zlepšení, ale nic zásadního, budu se těšit příště. “',
	},
]

export function CustomerReviews() {
	return (
		<div className="container flex flex-col gap-4 px-20">
			{REVIEWS?.map((review, index) => (
				<div>
					<p className="font-semibold">{review.reviewer}</p>
					<p>{review.review}</p>
				</div>
			))}
		</div>
	)
}
