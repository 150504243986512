import { type MetaFunction } from '@remix-run/node'
import { useEffect, useState } from 'react'
import BlueFooter from '#app/components/blue-footer.tsx'
import { Footer } from '#app/components/footer.tsx'
import InitialPopup from '#app/components/initial-popup.tsx'
import IzzySteps from '#app/components/izzy-steps.tsx'
import Tiles from '#app/components/tiles.tsx'
import { appStoreLink, googlePlayLink } from '#app/utils/misc.tsx'
import { CleanOrderForm } from './clean-order-form.tsx'
import { CustomerReviews } from './customer-reviews.tsx'
import { GoogleReviews } from './google-reviews.tsx'
import ProvidersCarousel from './providers-carousel.tsx'

export { action } from './clean-order-form.tsx'

export const meta: MetaFunction = () => [{ title: 'IZZY' }]

export default function Index() {
	const [showInitialPopup, setShowInitialPopup] = useState(false)

	// Note: Disable popup for now. Will definetely show it again in the future.
	// useEffect(() => {
	// 	const timer = setTimeout(() => {
	// 		setShowInitialPopup(true)
	// 	}, 10000)

	// 	return () => clearTimeout(timer)
	// }, [])

	return (
		<>
			<div className="bg-landing flex w-full flex-col items-center pt-32">
				{showInitialPopup && <InitialPopup />}
				<div className="pt-10 md:grid md:grid-flow-col">
					<div className="text-center text-sm font-medium uppercase leading-7 tracking-wider text-white md:mr-[10px] md:mt-[2px] md:text-right">
						Uklidíme vám kdykoliv
					</div>
					<div>
						<img
							width={158}
							height={28}
							src="/img/landing-label.svg"
							className="mx-auto md:mx-0"
							alt="IZZY"
						/>
					</div>
				</div>
				{/* mobile */}
				<div className="relative z-20 mx-4 mt-4 pb-24 text-center text-4xl font-semibold leading-10 text-white sm:hidden">
					Profesionální úklid s Izzy ještě {}
					<span className="relative inline-block">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="167"
							height="54"
							viewBox="0 0 167 54"
							fill="none"
							className="absolute -top-1 left-[-12%] -z-10 w-[130%] xs:top-0 "
						>
							<path
								d="M138.551 3.75563C107.495 -0.641215 37.9921 -3.00452 8.43117 22.717C-28.5199 54.8689 82.1771 55.7201 124.317 50.7469C149.53 47.7713 166 40.4908 166 29.9573C166 20.8889 137.401 7.25934 85.6403 9.83562"
								stroke="#F7658B"
								strokeWidth="2"
								strokeLinecap="round"
							/>
						</svg>
						dnes
					</span>
				</div>
				{/* desktop */}
				<div className="container relative z-20 mx-4 mt-4 hidden pb-28 text-center text-5xl font-semibold leading-[67.20px] text-white sm:block md:pb-32">
					Profesionální úklid s Izzy {}
					<span className="relative inline-block md:mr-6">
						<svg
							width="319"
							height="68"
							viewBox="0 0 319 70"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							className="absolute left-[-12%] top-0 -z-10 w-[130%]"
						>
							<path
								d="M265.264 4.60352C205.599 -1.1462 72.0696 -4.23668 15.2769 29.3992C-55.7141 71.444 156.958 72.5571 237.918 66.0536C286.358 62.1624 318 52.6418 318 38.8673C318 27.0085 263.055 9.1853 163.612 12.5543"
								stroke="#F7658B"
								strokeWidth="2"
								strokeLinecap="round"
							/>
						</svg>
						ještě dnes
					</span>
				</div>
			</div>
			<div className="mx-auto max-w-7xl lg:px-6">
				<CleanOrderForm />
			</div>
			<section className="mx-auto max-w-7xl px-6 pt-16 xs:px-12 sm:px-24">
				<Tiles
					tiles={[
						{
							img: '/img/phone.svg',
							alt: 'mobil',
							text: 'Přehledná\naplikace',
						},
						{
							img: '/img/tiles/coins.svg',
							alt: 'mince',
							text: 'Bezpečná\nplatba online',
						},
						{
							img: '/img/tiles/award.svg',
							alt: 'vítězný pohár',
							text: 'Pojištění\nodpovědnosti',
						},
						{
							img: '/img/tiles/clock.svg',
							alt: 'čas',
							text: 'Čas úklidu\npřizpůsobený vám',
						},
					]}
				/>
			</section>
			<IzzySteps />
			<section className="bg-primary pb-8 text-white lg:mt-12 lg:pt-12">
				<div className="mx-auto flex max-w-7xl">
					<div className="flex-[3]">
						<div className="relative px-6 pb-8 pt-14 lg:hidden">
							<div className="absolute left-0 right-0 top-0 h-1/2 bg-white"></div>
							<img
								width={547}
								height={554}
								src="/img/vacuum-cleaning.webp"
								alt="izzy"
								className="relative rounded-xl"
							/>
						</div>
						<div className="flex-1 px-6 text-[40px] font-semibold leading-[60px]">
							Úklidy po celé Praze, snadno
							<br />a rychle
							<svg
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className="ml-2 inline-block"
							>
								<rect width="20" height="20" rx="10" fill="#F7658B" />
							</svg>
						</div>
						<div className="my-4 px-6  leading-relaxed text-white">
							Nabízíme profesionální úklid veškerých prostor od bytů, domů a
							Airbnb až po kanceláře a společné bytové prostory.
							<br /> Dělejte co vás baví a úklid nechte na nás.
						</div>
						<div className="grid gap-4 px-6 pb-2">
							<div className="inline-flex  items-center gap-3">
								<img
									width={20}
									height={20}
									src="/img/white-bullet.svg"
									alt="white-bullet"
								/>
								<div className="leading-tight">Cenově transparentní služby</div>
							</div>
							<div className="inline-flex items-center gap-3">
								<img
									width={20}
									height={20}
									src="/img/white-bullet.svg"
									alt="white-bullet"
								/>
								<div className="leading-tight">Pojištění odpovědnosti</div>
							</div>
							<div className="inline-flex items-center gap-3">
								<img
									width={20}
									height={20}
									src="/img/white-bullet.svg"
									alt="white-bullet"
								/>
								<div className="leading-tight">
									Pokud nemáte čistící prostředky, přineseme si své
								</div>
							</div>
							<div className="inline-flex items-center gap-3">
								<img
									width={20}
									height={20}
									src="/img/white-bullet.svg"
									alt="white-bullet"
								/>
								<div className="leading-tight">
									Garance nejvyšší kvality úklidu
								</div>
							</div>
							<div className="inline-flex items-center gap-3">
								<img
									width={20}
									height={20}
									src="/img/white-bullet.svg"
									alt="white-bullet"
								/>
								<div className="leading-tight">
									Hodnocení úklidu a paní na úklid
								</div>
							</div>
						</div>
						<div className="flex gap-3 p-6">
							<a href={appStoreLink}>
								<img
									width={135}
									height={44}
									src="/img/app-store.svg"
									alt="app-store"
								/>
							</a>
							<a href={googlePlayLink}>
								<img
									width={135}
									height={44}
									src="/img/google-play.svg"
									alt="google-play"
								/>
							</a>
						</div>
					</div>
					<div className="grid flex-[20rem] shrink-0 place-items-center max-lg:hidden">
						<img
							width={547}
							height={554}
							src="/img/vacuum-cleaning.webp"
							alt="izzy"
							className="rounded-xl"
						/>
					</div>
				</div>
			</section>

			<section className="px-6">
				<h1 className="py-20 text-center text-[40px] font-semibold leading-[60px] text-slate-700">
					Spokojenost našich zákazníků
				</h1>
				<GoogleReviews />
			</section>

			<section className="px-6">
				<h1 className="py-20 text-center text-[40px] font-semibold leading-[60px] text-slate-700">
					Jak zákazníci hodnotí naše paní na úklid a jejich práci
				</h1>
				<CustomerReviews />
			</section>

			<section className="mx-auto max-w-7xl px-6 py-16 xs:px-12 2xl:px-0">
				<ProvidersCarousel />
			</section>

			<BlueFooter />

			<Footer />
		</>
	)
}
