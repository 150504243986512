import { Link } from '@remix-run/react'
import { useState, useEffect } from 'react'
import { BulletPoint } from '#app/components/bullet-point'
import { Button } from '#app/components/ui/button'
import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselPrevious,
	CarouselNext,
} from '#app/components/ui/carousel'
import { api } from '#app/utils/api'
import { type CleaningProvider } from '#app/utils/api/types'

export default function ProvidersCarousel() {
	const [providers, setProviders] = useState<CleaningProvider[]>([])
	useEffect(
		() =>
			void api.getProviders(0, 50).then(res => {
				setProviders(
					'data' in res.data
						? res.data.data
								.filter(x => !!x.rating?.average)
								.filter(
									provider =>
										![
											'668d660ebff5f3fb1920c1bc',
											'64189fd324409214ad27914b',
										].includes(provider._id),
								)
								.sort(() => 0.5 - Math.random())
								.slice(0, 6)
						: [],
				)
			}),
		[],
	)

	const bulletPoints = [
		'Profesionální přístup',
		'Pojištění odpovědnosti',
		'Pečlivost',
	]

	return (
		<div className="flex w-full flex-col items-center">
			<Carousel
				className="-mb-28 w-full px-8 xs:-mb-40 lg:hidden"
				opts={{ align: 'center', containScroll: false, loop: true }}
			>
				<CarouselContent>
					{providers.map(provider => (
						<CarouselItem key={provider._id} className="max-w-fit">
							<ProviderItem provider={provider} />
						</CarouselItem>
					))}
				</CarouselContent>
			</Carousel>
			<div className="rounded-3xl bg-[#F7658B]/20 px-8 py-16 lg:flex lg:w-full lg:items-center lg:justify-between lg:gap-12">
				<div className="flex flex-col gap-4 text-slate-700 lg:max-w-xl">
					<div className="mt-16 text-[40px] font-semibold leading-[60px] xs:mt-28 lg:mt-0">
						Naše paní na úklid
					</div>
					{/* TODO: add missing pink circle */}
					<div className="mt-2 text-[16px] leading-[26px]">
						Spolupracovnice si pečlivě vybíráme. Všechny paní na úklid prochází
						profesionálním zaškolením, abychom zajistili nejvyšší kvalitu
						služeb.
						<div className="mt-8 flex flex-col gap-4">
							{bulletPoints.map(point => (
								<BulletPoint key={point} text={point} variant="secondary" />
							))}
						</div>
					</div>
					<Button className="mt-4 w-[204px] bg-[#F7658B] hover:bg-[#F7658B]/80">
						<Link prefetch="intent" to="/signup?type=provider">
							Stát se paní na úklid
						</Link>
					</Button>
				</div>
				<Carousel
					className="mr-10 hidden max-w-md lg:block 2xl:max-w-lg"
					opts={{ align: 'center', containScroll: false, loop: true }}
				>
					<CarouselContent>
						{providers.map(provider => (
							<CarouselItem key={provider._id} className="max-w-fit">
								<ProviderItem provider={provider} />
							</CarouselItem>
						))}
					</CarouselContent>
					<CarouselPrevious />
					<CarouselNext />
				</Carousel>
			</div>
		</div>
	)
}

const ProviderItem = ({ provider }: { provider: CleaningProvider }) => {
	const [imgSrc, setImgSrc] = useState(api.getProviderImageUrl(provider._id))

	return (
		<Link
			prefetch="intent"
			to={`/dashboard/provider/${provider._id}`}
			className="relative overflow-hidden rounded-2xl"
		>
			<img
				width={232}
				height={291}
				src={imgSrc}
				alt={provider.businessName}
				onError={() => setImgSrc('/img/cleaning-providers/default.png')}
				className="relative h-[291px] w-[232px] rounded-2xl object-cover"
			/>
			<div className="absolute inset-0 h-[291px] rounded-2xl bg-gradient-to-t from-[#6C001C]/50 to-[#000000]/0"></div>
			<div className="relative bottom-12 left-4 w-[232px] text-[22px] font-medium leading-6 text-white">
				{provider.businessName}
			</div>
		</Link>
	)
}
